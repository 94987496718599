<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <ValidationObserver v-slot="{ handleSubmit }">
        <h4 slot="header" class="card-title">Add New Partner</h4>
        <form @submit.prevent="handleSubmit(submit)">
          <div>
            <div class="col-md-8">
               <ValidationProvider
                  name="Name"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Name"
                  v-model="model.name"
                  type="text"
                  placeholder="Enter partners name"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
               </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Type"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
               >
                <base-input
                required
                label="Type"
                v-model="model.type"
                type="text"
                placeholder="Enter partners type"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Phone"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Phone"
                  v-model="model.phone"
                  type="text"
                  placeholder="Enter partners phone"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Email"
                  v-model="model.email"
                  type="text"
                  placeholder="Enter partners email"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Address"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Address"
                  v-model="model.address"
                  type="text"
                  placeholder="Enter partners address"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
                <textarea class="form-text form-control" v-model="model.description" placeholder="Enter program description" label="Description" name="" id="" cols="30" rows="10"></textarea>
                <br>
            </div>
            <div class="col-md-8">
                <base-button class="mt-4" id="submit" native-type="submit" type="danger"
                >Add</base-button
                >
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
  data() {
    return {
        model: {
            name: null,
            phone: null,
            type: null,
            email: null,
            address: null,
            description: null,
        }
    };
  },
  methods: {
      submit(){
        document.getElementById('submit').disabled=true;
        let idDate = Number(new Date().valueOf());
        let email = this.model.email;
        let password = this.model.phone; 
        
        // add the data to db
        db.collection('partners').add({
            name: this.model.name,
            type: this.model.type,
            phone: this.model.phone,
            email: this.model.email,
            address: this.model.address,
            description: this.model.description,
            time: idDate,
            startups: 0

        })
        .then(docRef => {

                        firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log(errorCode + ": " + errorMessage);
                        // ...
                        }); 

                        db.collection('userbase').add({
                            email: email,
                            userID: docRef.id,
                            type: 'partner',
                        })
                        .then(docRef => {
                          console.log('Successfully added to userbase')
                        })


            console.log('Successfully added: ', docRef.id)
            // alert("Succefully Added Data");
            swal.fire({
              title: `Success!`,
              text: "You have succesfully added partner",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill"
              },
              icon: "success"
            });
            this.$router.push('/partners')
        })
        .catch(error => {
            console.error('Error adding Data: ', error)
        })

      }
  }
};
</script>
<style></style>
