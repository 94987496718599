<template>
  <div>
    <div class="row">
      <div  v-if="type == 1" class="col-md-3 col-sm-12 ml-auto">
        <router-link to="/programs/add" replace>
          <base-button class="btn-block" type="danger">
            <i class="fas fa-plus"></i>
            Add program</base-button
          >
        </router-link>
      </div>
    </div>
    <br />
    <div v-if="type != 3" class="row">
      <div class="col-md-12">
        <el-table :data="tableData">
          <el-table-column min-width="150" label="Program">
            <div class="program-link" slot-scope="{ row }">
              <router-link
                v-bind:to="{
                  name: 'Programs-Detail',
                  params: { program_id: row.id },
                }"
              >
                {{ row.program }}
              </router-link>
            </div>
          </el-table-column>
          <el-table-column min-width="200" prop="incubated" label="Incubated">
          </el-table-column>
          <el-table-column min-width="150" align="center" label="Deadline">
            <div slot-scope="{ row }">
              {{ row.end.toDate() | moment("MMM YYYY") }}
            </div>
          </el-table-column>
          <el-table-column
            min-width="150"
            header-align="right"
            align="right"
            label="Options"
          >
            <div slot-scope="{ row, $index }" class="text-right table-actions">
              <el-tooltip
                content="Edit"
                effect="light"
                :open-delay="300"
                placement="top"
              >
               <router-link
                    :to="{
                      name: 'Program-Edit',
                      params: { program_id: row.id },
                    }"
                    replace
                  >
                <base-button icon size="sm" class="btn-link">
                  <i class="fas fa-edit"></i>
                </base-button>
               </router-link>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="type == 3" class="row">
      <div class="col-md-12">
        <el-table :data="tableDataProgram">
          <el-table-column min-width="150" label="Program">
            <div class="program-link" slot-scope="{ row }">
              <router-link
                v-bind:to="{
                  name: 'Programs-Detail',
                  params: { program_id: row.id },
                }"
              >
                {{ row.program }}
              </router-link>
            </div>
          </el-table-column>
          <el-table-column min-width="200" prop="incubated" label="Incubated">
          </el-table-column>
          <el-table-column min-width="150" align="center" label="Deadline">
            <div slot-scope="{ row }">
              {{ row.end.toDate() | moment("MMM YYYY") }}
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import db from "@/components/Firebase/firebaseInit";
import VueMoment from "vue-moment";
import moment from "moment";
import firebase from 'firebase';


export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueMoment,
    moment,
  },
  data() {
    return {
      tableData: [],
      tableDataProgram: [],
    };
  },
  computed: {
    type(){
      let x = this.$store.state.models.type;
      let y;
      if(x == 'admin'){
        y = 1;
      }else if(x == 'program'){
        y = 2;
      }else if(x == 'partner'){
        y = 3;
      }else if(x == 'startup'){
        y = 4;
      }
       return y;
    },
  },
  created() {
    db.collection("programs")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let incubated;
          if (doc.data().startups == null) {
            incubated = 0;
          } else {
            incubated = doc.data().startups;
          }
          const data = {
            id: doc.id,
            program: doc.data().name,
            incubated: incubated,
            end: doc.data().end,
          };
          this.tableData.push(data);
        });
      });

let programTable = this.tableDataProgram;
db.collection("partners").where('email', '==', this.$store.state.models.email)
  .get()
  .then((querySnapshot) => {
    querySnapshot.forEach((doc) => {
      let partnerID = doc.id; 

    db.collection("partners/" + partnerID +"/programs")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => { 
          let programID = doc.id;   
          console.log(partnerID + " is the ID");

    db.collection("programs").where(firebase.firestore.FieldPath.documentId(), '==' , programID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let incubated;
          if (doc.data().startups == null) {
            incubated = 0;
          } else {
            incubated = doc.data().startups;
          }
          const data = {
            id: doc.id,
            program: doc.data().name,
            incubated: incubated,
            end: doc.data().end,
          };
          programTable.push(data);
        });
      });

        })
      });

    })
  });

  },
  methods: {},
};
</script>
<style>
.cell a {
  color: #ec250d !important;
}
</style>
