<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Add New Startup</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div> 
            <div class="col-md-8">
              <ValidationProvider
                    name="Program Name"
                    rules="required"
                    v-slot="{ errors }"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Select Program"
                  v-model="model.program"
                  :error="errors[0]">
                  <el-option
                    v-for="option in partners"
                    class="select-danger"
                    :value="option.value"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Name"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Name"
                  v-model="model.name"
                  type="text"
                  placeholder="Enter startup name"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Startup Type"
                    rules="required"
                    v-slot="{ errors }"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Select Startup Type"
                  v-model="model.type"
                  :error="errors[0]">
                  <el-option
                    class="select-danger"
                    value="Software (SaaS) and technology"
                    label="Software (SaaS) and technology"
                    key="Software (SaaS) and technology"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Marketing and advertising"
                    label="Marketing and advertising"
                    key="Marketing and advertising"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Healthcare"
                    label="Healthcare"
                    key="Healthcare"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="IT"
                    label="IT"
                    key="IT"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Insurance"
                    label="Insurance"
                    key="Insurance"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Education"
                    label="Education"
                    key="Education"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Real estate"
                    label="Real estate"
                    key="Real estate"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Environmental and energy"
                    label="Environmental and energy"
                    key="Environmental and energy"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Retail and ecommerce"
                    label="Retail and ecommerce"
                    key="Retail and ecommerce"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Blockchain and cryptocurrencies"
                    label="Blockchain and cryptocurrencies"
                    key="Blockchain and cryptocurrencies"
                  >
                  </el-option>

                </el-select>
              </ValidationProvider>
            </div>
            <br>
            <div class="col-md-8">
              <ValidationProvider
                    name="Startup Stage"
                    rules="required"
                    v-slot="{ errors }"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Select Startup Stage"
                  v-model="model.stage"
                  :error="errors[0]">
                  <el-option
                    class="select-danger"
                    value="Self-funding"
                    label="Self-funding"
                    key="Self-funding"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Pre-Seed"
                    label="Pre-Seed"
                    key="Pre-Seed"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Seed"
                    label="Seed"
                    key="Seed"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Venture"
                    label="Venture"
                    key="Venture"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Series A"
                    label="Series A"
                    key="Series A"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Series B"
                    label="Series B"
                    key="Series B"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Series C"
                    label="Series C"
                    key="Series C"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="IPO"
                    label="IPO"
                    key="IPO"
                  >
                  </el-option>
                </el-select>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Phone"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Phone No."
                  v-model="model.phone"
                  type="text"
                  placeholder="Enter startup phone No."
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Email"
                    rules="required|email"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="Email"
                v-model="model.email"
                type="text"
                placeholder="Enter startup email"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Address"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="Address"
                v-model="model.address"
                type="text"
                placeholder="Enter startup address"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="City"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="City"
                v-model="model.city"
                type="text"
                placeholder="Enter startup city"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Website"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="Website"
                v-model="model.website"
                type="text"
                placeholder="Enter startup website"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Valuation"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Valuation(USD)"
                  v-model="model.valuation"
                  type="text"
                  placeholder="Enter startup valuation (USD)"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Initial Capital"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Initial Capital / Investment"
                  v-model="model.initialCap"
                  type="text"
                  placeholder="Enter startup initial capital / Investment"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Profits"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Profits"
                  v-model="model.profits"
                  type="text"
                  placeholder="Enter startup profits"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Assets"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Assets"
                  v-model="model.assets"
                  type="text"
                  placeholder="Enter startup assets"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Jobs Created"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Jobs Created"
                  v-model="model.jobs"
                  type="text"
                  placeholder="Enter startup Jobs Created"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Team"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Team"
                  v-model="model.team"
                  type="text"
                  placeholder="Enter number of team meambers"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
                <textarea class="form-text form-control" v-model="model.description" placeholder="Enter description" label="Description" name="" id="" cols="30" rows="10"></textarea>
                <br>
            </div>
            <div class="col-md-8">
                <base-button class="mt-4" id="submit" native-type="submit" type="danger"
                >Add</base-button
                >
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Select, Option } from 'element-ui';
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      partners: [],
        model: {
            name: null,
            program: null,
            type: null,
            address: null,
            team: null,
            city: null,
            website: null,
            email: null,
            phone: null,
            description: null,
            valuationn: null,
            initialCap: null,
            profits: null,
            jobs: null,
            assets: null,
            stage: null,
            valuation: null
        }
    };
  },
  created(){
    db.collection('programs').get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const data = {
          'id': doc.id,
          'name': doc.data().name,
          'partnerID': doc.data().partnerID,
          'partnerName': doc.data().partnerName,
          'value': doc.data().name + "--" + doc.id + "--" + doc.data().partnerID + "--" + doc.data().partnerName
        }
        this.partners.push(data)
      })
    })
  },
  methods: {
      submit(){
        document.getElementById('submit').disabled=true;

        let idDate = Number(new Date().valueOf());
        let content = this.model.program.split("--");
        let name = content[0];
        let id = content[1];
        let partnerID = content[2];
        let partnerName = content[3];
        let email = this.model.email;
        let password = this.model.phone;

        // add the data to db
        db.collection('startups').add({
            name: this.model.name,
            program: name,
            programID: id,
            partnerID: partnerID,
            partnerName: partnerName,
            type: this.model.type,
            phone: this.model.phone,
            email: this.model.email,
            address: this.model.address,
            stage: this.model.stage,
            city: this.model.city,
            website: this.model.website,
            team: this.model.team,
            description: this.model.description,
            valuation: this.model.valuation,
            initialCap: this.model.initialCap,
            time: idDate,
            jobs: this.model.jobs,
            assets: this.model.assets,
            profits: this.model.profits,
            totalMilestones: 0,
            completedMilestones: 0,
            meambers: 0,  
            businessModel : 0,
            businessModelNow : 0, 
            finance : 0,
            financeNow : 0, 
            productDev : 0, 
            productDevNow : 0, 
            marketing : 0,
            marketingNow : 0, 
            teamBuilding : 0, 
            teamBuildingNow : 0, 
            fundraising : 0, 
            fundraisingNow : 0 


        })
        .then(docRef => {

                        firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log(errorCode + ": " + errorMessage);
                        // ...
                        }); 


              db.collection('userbase').add({
                  email: email,
                  userID: docRef.id,
                  type: 'startup',
              })
              .then(docRef => {
                console.log('Successfully added to userbase')
              })



            let content = this.model.program.split("--");
            let id = content[1];
            let partnerID = content[2];
            db.collection('programs').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let startups = Number(doc.data().startups) + 1;
                    let jobs = Number(doc.data().jobs) + Number(this.model.jobs);
                    let profits = Number(doc.data().profits) + Number(this.model.profits);
                    let assets = Number(doc.data().assets) + Number(this.model.assets);
                    let initialCap = Number(doc.data().initialCap) + Number(this.model.initialCap);
                    let valuation = Number(doc.data().valuation) + Number(this.model.valuation);
                    doc.ref.update({
                        startups: startups,
                        jobs: jobs,
                        profits: profits,
                        assets: assets,
                        initialCap: initialCap,
                        valuation: valuation
                    })
                    .then(() => {
                        console.log('Completed')
                    });
                })
            })
            db.collection('partners').where(firebase.firestore.FieldPath.documentId(), '==', partnerID).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let startups = Number(doc.data().startups) + 1;
                    doc.ref.update({
                        startups: startups
                    })
                    .then(() => {
                        console.log('Completed')
                    });
                })
            })

            console.log('Successfully added: ', docRef.id)
            // alert("Succefully Added Data");
            swal.fire({
              title: `Success!`,
              text: "You have succesfully added startup",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill"
              },
              icon: "success"
            });
            this.$router.push('/startups')
        })
        .catch(error => {
            console.error('Error adding Data: ', error)
        })

      }
  }
};
</script>
<style></style>
