<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Add New Program</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div>
            <div class="col-md-8">
                  <ValidationProvider
                    name="Name"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
                  >
                    <base-input
                    required
                    label="Name"
                    v-model="model.name"
                    type="text"
                    placeholder="Enter program name"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    </base-input>
                  </ValidationProvider>
            </div>
            <!-- <div class="col-md-8">
                 <ValidationProvider
                    name="Email"
                    rules="required|email"
                    v-slot="{ passed, failed, errors }"
                  >
                    <base-input
                    required
                    label="Email"
                    v-model="model.email"
                    type="text"
                    placeholder="Enter program Manager Email"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    </base-input>
                 </ValidationProvider>
            </div> -->
            <div class="col-md-8">
              <ValidationProvider
                    name="Select Partners"
                    rules="required"
                    v-slot="{ errors }"
              >
                <el-select
                  multiple
                  class="select-primary"
                  size="large"
                  collapse-tags
                  placeholder="Select Partners"
                  v-model="model.partner"
                  :error="errors[0]">
                  <el-option
                    v-for="option in partners"
                    class="select-danger"
                    :value="option.value"
                    :label="option.name"
                    :key="option.id"
                  >
                  </el-option>
                </el-select>
              </ValidationProvider>
            </div>
            <br>
            <div class="col-md-8">
              <ValidationProvider
                    name="Start Date"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
              >
                <el-date-picker 
                  required
                  type="date"
                  label="Start Date"
                  placeholder="Start Date"
                  v-model="model.start"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </el-date-picker>
              </ValidationProvider>
            </div>
            <br>
            <div class="col-md-8">
              <ValidationProvider
                    name="End Date"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
              >
                  <el-date-picker
                    required
                    type="date"
                    label="End Date"
                    placeholder="End Date"
                    v-model="model.end"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </el-date-picker>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
                <textarea class="form-text form-control" v-model="model.description" placeholder="Enter program description" label="Description" name="" id="" cols="30" rows="10"></textarea>
                <br>
            </div>
            <div class="col-md-8">
                <base-button class="mt-4" id="submit" native-type="submit" type="danger"
                >Save</base-button
                >
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Select, Option } from 'element-ui';
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      partners: [],
        model: {
            name: null,
            id: null,
            start: null,
            end: null,
            partner: null,
            description: null,
            email: null,
        }
    };
  },
  created(){
    db.collection('partners').get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        const data = {
          'id': doc.id,
          'name': doc.data().name,
          'value': doc.id + "--" + doc.data().name
        }
        this.partners.push(data)
      })
    })
  },
  beforeRouteEnter(to, from, next) {
    console.log(to.params.startup_doc_id + " sdf")
    db
      .collection('programs').where(firebase.firestore.FieldPath.documentId(), '==', to.params.program_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.model.name = doc.data().name; 
            vm.model.id = doc.data().programID; 
            vm.model.start = new Date(Number(doc.data().start.seconds) * 1000); 
            vm.model.end = new Date(Number(doc.data().end.seconds) * 1000); 
            vm.model.description = doc.data().description; 
            vm.model.email = doc.data().email; 

            let partnerData = doc.data().partner;
                partnerData.forEach(unsplitFunction); 
                function unsplitFunction(item, index) {
                    let name = item.name;
                    let id = item.id;
                    let data = id + "--" + name;
                    vm.model.partner.push(data);
                  }

          });
        });
      });
  },
   watch: {
    $route: 'fetchData'
  },
  methods: {
      fetchData () {
        db.collection('programs')
          .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.program_id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                this.model.name = doc.data().name; 
                this.model.id = doc.data().programID; 
                this.model.start = new Date(Number(doc.data().start.seconds) * 1000); 
                this.model.end = new Date (Number(doc.data().end.seconds) * 1000); 
                this.model.description = doc.data().description; 
                this.model.email = doc.data().email; 

                let partnerData = doc.data().partner;
                partnerData.forEach(unsplitFunction); 
                function unsplitFunction(item, index) {
                    let name = item.name;
                    let id = item.id;
                    let data = id + "--" + name;
                    this.model.partner.push(data);
                  }

            });
          });
      },
      submit(){
      document.getElementById('submit').disabled=true;

        // Values 
        let partnerData = [];
        let programID = this.model.id;
        let ProgramName = this.model.name;
        let programStart = this.model.start;
        let programEnd = this.model.end;
        let programDescription = this.model.description;
        let email = this.model.email;
        let eachPartner = this.model.partner;
        let router = this.$router;


                db.collection('startups').where('programID', '==', programID).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.update({
                          program: ProgramName
                    })
                    .then(() => {
                    console.log('module have been Updated')
                    });
                  });
                });



                   
                    //Add to the partners page
                    eachPartner.forEach(splitFunction); 
                      function splitFunction(item, index) {
                        let content = item.split("--");
                        let id = content[0];
                        let name = content[1];
                        let data = {
                            'id': id,
                            'name': name,
                          }
                        partnerData.push(data);


                        // add the data to db
                        console.log(id + " is the id");
                        db.doc('partners/'+ id + '/programs/' + programID).set({
                            name: ProgramName,
                            programID: programID,
                            start: programStart,
                            end: programEnd,
                            description: programDescription
                            }, 
                            {
                              merge: true 
                            })
                        .catch(error => {
                            console.error('Error adding Data: ', error)
                        })

                      }
                      //End of function

                      // add the data to db
                      db.collection('programs').doc(programID).set({
                          name: ProgramName,
                          partner: partnerData,
                          start: programStart,
                          end: programEnd,
                          description: programDescription
                      }, 
                      {
                         merge: true 
                      })
                      .then(docRef => {


                          console.log('Successfully added')
                          // alert("Succefully Added Data");
                          swal.fire({
                            title: `Success!`,
                            text: "You have succesfully editied the program",
                            buttonsStyling: false,
                            customClass: {
                              confirmButton: "btn btn-danger btn-fill"
                            },
                            icon: "success"
                          });
                          router.push('/programs')
                      })
                      .catch(error => {
                          console.error('Error adding Data: ', error)
                      })
                      



      }
  }
};
</script>
<style>
</style>
