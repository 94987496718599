var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',{staticClass:"stacked-form",attrs:{"title":"Stacked Form"}},[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Add New Milestone")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',[_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"Name","type":"text","placeholder":"Enter milestone name","error":errors[0]},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Timeline","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"label":"Timeline","type":"text","placeholder":"How many workdays it take","error":errors[0]},model:{value:(_vm.model.workdays),callback:function ($$v) {_vm.$set(_vm.model, "workdays", $$v)},expression:"model.workdays"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('tags-input',{attrs:{"label":"Tags"},model:{value:(_vm.model.tags),callback:function ($$v) {_vm.$set(_vm.model, "tags", $$v)},expression:"model.tags"}})],1),_c('div',{staticClass:"col-md-8"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.description),expression:"model.description"}],staticClass:"form-text form-control",attrs:{"placeholder":"Enter description","label":"Description","name":"","id":"","cols":"30","rows":"10"},domProps:{"value":(_vm.model.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "description", $event.target.value)}}}),_c('br')]),_c('div',{staticClass:"col-md-8"},[_c('base-button',{staticClass:"mt-4",attrs:{"id":"submit","native-type":"submit","type":"danger"}},[_vm._v("Add")])],1)])])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }