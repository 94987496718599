<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Edit Logo</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div>
             <div class="row">
              <!-- <label class="col-sm-2 col-form-label">Image</label> -->
              <div class="col-sm-4">
                <!-- <fg-input v-model="imageURL" type="text" placeholder="Image URL">
                </fg-input> -->
                <el-upload class="picture"
                     action="https://jsonplaceholder.typicode.com/posts/"
                     :on-change="handlePreview"
                     :auto-upload="false"
                     :show-file-list="false">
                    <img v-if="model.imageUrl" :src="model.imageUrl" class="picture-src">
                </el-upload>
              </div>
            </div>
            <div class="col-md-8">
                <base-button class="mt-4" id="submit" native-type="submit" type="danger"
                >Save</base-button
                >
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Select, Option } from 'element-ui';
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import {
  TagsInput
} from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';
import { Upload } from 'element-ui'; 

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    TagsInput,
    [Upload.name]: Upload
  },
  data() {
    return {
        imageUrl: null,
        uploadValue: null,
        picture: null,
        imageData: null,
        model: {
            name: null,
            phone: null,
            type: null,
            email: null,
            address: null,
            description: null,
            imageUrl: 'img/image_placeholder.jpg'
        }
    };
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to.params.startup_doc_id + " sdf")
    db
      .collection('startups').where(firebase.firestore.FieldPath.documentId(), '==', to.params.startupID)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.picture = doc.data().logo;
            if(doc.data().logo != null){
                vm.model.imageUrl = doc.data().logo;
            }
          });
        });
      });
  },
   watch: {
    $route: 'fetchData'
  },
  methods: {
     handlePreview(file) {
        this.model.imageUrl = URL.createObjectURL(file.raw);
        this.imageData = event.target.files[0];
        },
      fetchData () {
        db.collection('startups')
          .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.startupID)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                this.picture = doc.data().logo;
                if(doc.data().logo != null){
                this.model.imageUrl = doc.data().logo;
                }

            });
          });
      },
      submit(){
      document.getElementById('submit').disabled=true;

        // Values 
        let router = this.$router;
        // let logo = this.picture;


        if(this.imageData == null){


              db.collection('startups').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.startupID).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.update({
                          logo: this.picture
                    })
                    .then(() => {
                    console.log('module have been Updated')
                          // alert("Succefully Added Data");
                          swal.fire({
                            title: `Success!`,
                            text: "You have succesfully editied the item",
                            buttonsStyling: false,
                            customClass: {
                              confirmButton: "btn btn-danger btn-fill"
                            },
                            icon: "success"
                          });
                          router.push('/dashboard')
                    });
                  });
                });


        }else{
          
      this.picture=null;
      let timename = Date.now();
      const storageRef=firebase.storage().ref(`${timename}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.picture =url;
        //   console.log(this.picture);

              db.collection('startups').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.startupID).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.update({
                          logo: this.picture
                    })
                    .then(() => {
                    console.log('module have been Updated')
                          // alert("Succefully Added Data");
                          swal.fire({
                            title: `Success!`,
                            text: "You have succesfully editied the item",
                            buttonsStyling: false,
                            customClass: {
                              confirmButton: "btn btn-danger btn-fill"
                            },
                            icon: "success"
                          });
                          router.push('/dashboard')
                    });
                  });
                });



          });
        }
      );

        }

      },

  }
};
</script>
<style>
</style>
