<template>
  <div class="login-content">
    <div class="row">
      <div class="col-md-6 col-sm-12 mr-auto">
        <div class="container">
          <div class="row">
            <div class="col-md-8 mar-top mr-auto ml-auto">
              <img src="img/ice180.png" width="50%" alt="">
                <p class="card-description">Incubation program aimed to facilitate the ideation, prototyping, development and scaling of promising Ethiopian startups.
                </p>
            </div>
            <div class="col-md-8 mar-top mr-auto ml-auto">
              <h2 class="card-title">
                Login
              </h2>
            <div>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
              <base-input
                required
                label="Email"
                v-model="email"
                type="email"
                placeholder="Email"
                addon-left-icon="tim-icons icon-email-85"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              </base-input>
             </ValidationProvider>

             <ValidationProvider
               name="password"
               rules="required|min:5"
               v-slot="{ passed, failed, errors }"
             >
             <base-input
               required
               v-model="password"
               label="Password"
               placeholder="Password"
               addon-left-icon="tim-icons icon-lock-circle"
               type="password"
               :error="errors[0]"
               :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
             </base-input>
            </ValidationProvider>
            </div>

            <div slot="footer" class="mar-top">
              <base-button @click.native="login" type="danger" class="mb-3" size="lg" block>
                Login
              </base-button>
              <!-- <div class="pull-left">
                <h6>
                  <router-link class="link footer-link" to="/register">
                    Create Account
                  </router-link>
                </h6>
              </div>

              <div class="pull-right">
                <h6><a href="#pablo" class="link footer-link">Need Help?</a></h6>
              </div> -->
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <img src="img/loginback.png" width="100%" alt="">
      </div>
    </div>
  </div>
</template>
<script>

import { BaseCheckbox } from 'src/components';
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);

export default {
  components: {
    BaseCheckbox
  },
  data() {
    return {
        email: '',
        fullname: '',
        password: ''

    };
  },
  methods: {
    submit() {
      alert("Form has been submitted!");
    }
  }
};
</script>
<style scoped>
.mar-top{
  margin-top: 50px;
}
</style>
