<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Add New Milestone</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Name"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Name"
                  v-model="model.name"
                  type="text"
                  placeholder="Enter milestone name"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Timeline"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  label="Timeline"
                  v-model="model.workdays"
                  type="text"
                  placeholder="How many workdays it take"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
                <tags-input label="Tags" v-model="model.tags"></tags-input>
            </div>
            <div class="col-md-8">
                <textarea class="form-text form-control" v-model="model.description" placeholder="Enter description" label="Description" name="" id="" cols="30" rows="10"></textarea>
                <br>
            </div>
            <div class="col-md-8">
                <base-button class="mt-4"  id="submit" native-type="submit" type="danger"
                >Add</base-button
                >
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import {
  TagsInput
} from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
    components: {
     TagsInput
    },
  data() {
    return {
        model: {
            name: null,
            description: null,
            workdays: null,
            description: null,
            tags: ['business'],
        }
    };
  },
  methods: {
      submit(){
        document.getElementById('submit').disabled=true;
        let idDate = Number(new Date().valueOf());

        // add the data to db
        db.collection('milestones-startup').add({
            name: this.model.name,
            description: this.model.description,
            tags: this.model.tags,
            workdays: this.model.workdays,
            program: this.$route.params.program_id,
            startup: this.$route.params.startup_id,
            description: this.model.description,
            time: idDate,
            status: 'created'

        })
        .then(docRef => {


            db.collection('startups').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.startup_doc_id).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let milestone = Number(doc.data().totalMilestones) + 1;
                    doc.ref.update({
                        totalMilestones: milestone
                    })
                    .then(() => {
                        console.log('Completed')
                    });
                })
            })

            console.log('Successfully added: ', docRef.id)
            // alert("Succefully Added Data");
            swal.fire({
              title: `Success!`,
              text: "You have succesfully added milestone",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill"
              },
              icon: "success"
            });
            this.$router.push('/startups')
        })
        .catch(error => {
            console.error('Error adding Data: ', error)
        })

      }
  }
};
</script>
<style></style>
