var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12 mr-auto login-left"},[_c('div',{staticClass:"row login-container"},[_vm._m(0),_c('div',{staticClass:"login"},[_c('h2',{staticClass:"card-title"},[_vm._v("Log in")]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"Email Address","type":"email","placeholder":"you@email.com","addon-left-icon":"lni lni-envelope","error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"Password","placeholder":"Password","addon-left-icon":"lni lni-lock-alt","type":"password","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"login-after"},[_c('a',{staticClass:"forgot-password",attrs:{"href":"/#/forgotpassword"}},[_vm._v("Forgot Password?")]),_c('base-checkbox',[_vm._v(" Keep me logged in ")])],1)]}}])})],1),_c('div',{staticClass:"mar-top",attrs:{"slot":"footer"},slot:"footer"},[(_vm.loginButton == true)?_c('div',[_c('base-button',{staticClass:"login-btn",attrs:{"type":"danger","size":"lg","block":"","disabled":""},nativeOn:{"click":function($event){return _vm.login.apply(null, arguments)}}},[_c('svg',{attrs:{"width":"20","version":"1.1","id":"L3","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 100 100","enable-background":"new 0 0 0 0","xml:space":"preserve"}},[_c('circle',{staticStyle:{"opacity":"0.5"},attrs:{"fill":"none","stroke":"#fff","stroke-width":"4","cx":"50","cy":"50","r":"44"}}),_c('circle',{attrs:{"fill":"#fff","stroke":"#e74c3c","stroke-width":"3","cx":"8","cy":"54","r":"6"}},[_c('animateTransform',{attrs:{"attributeName":"transform","dur":"2s","type":"rotate","from":"0 50 48","to":"360 50 52","repeatCount":"indefinite"}})],1)])])],1):_vm._e(),(_vm.loginButton == false)?_c('div',[_c('base-button',{staticClass:"login-btn",attrs:{"type":"danger","size":"lg","block":""},nativeOn:{"click":function($event){return _vm.login.apply(null, arguments)}}},[_vm._v(" Login ")])],1):_vm._e()])])])]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('img',{staticClass:"ice180-logo",attrs:{"src":"img/logo.svg","alt":"ice180"}}),_c('br'),_c('br'),_c('p',{staticClass:"card-description"},[_vm._v(" Startups and SMEs tracking tool for startup support programs, incubators and accelerators. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-6 col-sm-12 login-right"},[_c('img',{attrs:{"src":"img/login-animated.svg","width":"100%","alt":""}})])}]

export { render, staticRenderFns }