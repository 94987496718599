<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Progress Edit</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div>
         <div class="row">
            <div class="col-md-5">
              <ValidationProvider
                  name="Business Model"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Business Model Initial"
                  v-model="businessModel"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-5">
              <ValidationProvider
                  name="Business Model"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Business Model Now"
                  v-model="businessModelNow"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
         </div>
         <div class="row">
            <div class="col-md-5">
              <ValidationProvider
                  name="Finance"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Finance Initial"
                  v-model="finance"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-5">
              <ValidationProvider
                  name="Finance"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Finance Now"
                  v-model="financeNow"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
         </div>
         <div class="row">
            <div class="col-md-5">
              <ValidationProvider
                  name="Product Dev"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Product Dev Initial"
                  v-model="productDev"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-5">
              <ValidationProvider
                  name="Product Dev"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Product Dev Now"
                  v-model="productDevNow"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
         </div>
         <div class="row">
            <div class="col-md-5">
              <ValidationProvider
                  name="Marketing"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Marketing Initial"
                  v-model="marketing"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-5">
              <ValidationProvider
                  name="Marketing"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Marketing Now"
                  v-model="marketingNow"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
         </div>
         <div class="row">
            <div class="col-md-5">
              <ValidationProvider
                  name="Team Building"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Team Building Initial"
                  v-model="teamBuilding"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-5">
              <ValidationProvider
                  name="Team Building"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Team Building Now"
                  v-model="teamBuildingNow"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
         </div>
         <div class="row">
            <div class="col-md-5">
              <ValidationProvider
                  name="Fundraising"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Fundraising Initial"
                  v-model="fundraising"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-5">
              <ValidationProvider
                  name="Fundraising"
                  rules="required|numeric"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Fundraising Now"
                  v-model="fundraisingNow"
                  type="number"
                  placeholder="10-100"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
         </div>
         <div class="row">
            <div class="col-md-5">
                <base-button class="mt-4"  id="submit" native-type="submit" type="danger"
                >Save</base-button
                >
            </div>
         </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";

import {
  TagsInput
} from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
    components: {
     TagsInput
    }, 
  data() {
    return {
        businessModel: null,
        businessModelNow: null,
        finance: null,
        financeNow: null,
        productDev: null,
        productDevNow: null,
        marketing: null,
        marketingNow: null,
        teamBuilding: null,
        teamBuildingNow: null,
        fundraising: null,
        fundraisingNow: null,  
        businessModelOld: null,
        businessModelNowOld: null,
        financeOld: null,
        financeNowOld: null,
        productDevOld: null,
        productDevNowOld: null,
        marketingOld: null,
        marketingNowOld: null,
        teamBuildingOld: null,
        teamBuildingNowOld: null,
        fundraisingOld: null,
        fundraisingNowOld: null,
        model: {
            name: null,
            description: null,
            workdays: null,
            description: null,
            tags: ['business'],
        }
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(to.params.startup_doc_id + " sdf")
    db
      .collection('startups').where(firebase.firestore.FieldPath.documentId(), '==', to.params.startup_doc_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.businessModel = doc.data().businessModel; 
            vm.businessModelNow = doc.data().businessModelNow; 
            vm.finance = doc.data().finance; 
            vm.financeNow = doc.data().financeNow; 
            vm.productDev = doc.data().productDev; 
            vm.productDevNow = doc.data().productDevNow; 
            vm.marketing = doc.data().marketing; 
            vm.marketingNow = doc.data().marketingNow; 
            vm.teamBuilding = doc.data().teamBuilding; 
            vm.teamBuildingNow = doc.data().teamBuildingNow; 
            vm.fundraising = doc.data().fundraising; 
            vm.fundraisingNow = doc.data().fundraisingNow; 

            vm.businessModelOld = doc.data().businessModel; 
            vm.businessModelNowOld = doc.data().businessModelNow; 
            vm.financeOld = doc.data().finance; 
            vm.financeNowOld = doc.data().financeNow; 
            vm.productDevOld = doc.data().productDev; 
            vm.productDevNowOld = doc.data().productDevNow; 
            vm.marketingOld = doc.data().marketing; 
            vm.marketingNowOld = doc.data().marketingNow; 
            vm.teamBuildingOld = doc.data().teamBuilding; 
            vm.teamBuildingNowOld = doc.data().teamBuildingNow; 
            vm.fundraisingOld = doc.data().fundraising; 
            vm.fundraisingNowOld = doc.data().fundraisingNow; 
          });
        });
      });
  },
   watch: {
    $route: 'fetchData'
  },
  methods: {
      submit(){
        document.getElementById('submit').disabled=true;
        // let idDate = Number(new Date().valueOf());

            db.collection('startups').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.startup_doc_id).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  doc.ref.update({
                          businessModel : this.businessModel,
                          businessModelNow : this.businessModelNow, 
                          finance : this.finance,
                          financeNow : this.financeNow, 
                          productDev : this.productDev, 
                          productDevNow : this.productDevNow, 
                          marketing : this.marketing,
                          marketingNow : this.marketingNow, 
                          teamBuilding : this.teamBuilding, 
                          teamBuildingNow : this.teamBuildingNow, 
                          fundraising : this.fundraising, 
                          fundraisingNow : this.fundraisingNow 

                  })
                  .then(() => {

                    db.collection('programs').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.program_id).get().then((querySnapshot) => {
                          querySnapshot.forEach((doc) => {

                              let businessModelNew = Number(this.businessModel) - Number(this.businessModelOld);
                              let businessModelNowNew = Number(this.businessModelNow) - Number(this.businessModelNowOld);
                              let financeNew = Number(this.finance) - Number(this.financeOld);
                              let financeNowNew = Number(this.financeNow) - Number(this.financeNowOld);
                              let productDevNew = Number(this.productDev) - Number(this.productDevOld);
                              let productDevNowNew = Number(this.productDevNow) - Number(this.productDevNowOld);
                              let marketingNew = Number(this.marketing) - Number(this.marketingOld);
                              let marketingNowNew = Number(this.marketingNow) - Number(this.marketingNowOld);
                              let teamBuildingNew = Number(this.teamBuilding) - Number(this.teamBuildingOld);
                              let teamBuildingNowNew = Number(this.teamBuildingNow) - Number(this.teamBuildingNowOld);
                              let fundraisingNew = Number(this.fundraising) - Number(this.fundraisingOld);
                              let fundraisingNowNew = Number(this.fundraisingNow) - Number(this.fundraisingNowOld);

                              let businessModel = Number(doc.data().businessModel) + businessModelNew;
                              let businessModelNow = Number(doc.data().businessModelNow) + businessModelNowNew;
                              let finance = Number(doc.data().finance) + financeNew;
                              let financeNow = Number(doc.data().financeNow) + financeNowNew;
                              let productDev = Number(doc.data().productDev) + productDevNew;
                              let productDevNow = Number(doc.data().productDevNow) + productDevNowNew;
                              let marketing = Number(doc.data().marketing) + marketingNew;
                              let marketingNow = Number(doc.data().marketingNow) + marketingNowNew;
                              let teamBuilding = Number(doc.data().teamBuilding) + teamBuildingNew;
                              let teamBuildingNow = Number(doc.data().teamBuildingNow) + teamBuildingNowNew;
                              let fundraising = Number(doc.data().fundraising) + fundraisingNew;
                              let fundraisingNow = Number(doc.data().fundraisingNow) + fundraisingNowNew;

                                // console.log(businessModelNew + "New");
                                // console.log(businessModel + " Data Set");
                                // console.log(this.businessModelOld + " old");
                                // console.log(this.businessModel + " current");
                                // console.log(doc.data().businessModel + " database");


                              doc.ref.update({
                                  businessModel : businessModel,
                                  businessModelNow : businessModelNow, 
                                  finance : finance,
                                  financeNow : financeNow, 
                                  productDev : productDev, 
                                  productDevNow : productDevNow, 
                                  marketing : marketing,
                                  marketingNow : marketingNow, 
                                  teamBuilding : teamBuilding, 
                                  teamBuildingNow : teamBuildingNow, 
                                  fundraising : fundraising, 
                                  fundraisingNow : fundraisingNow 
                               })
                              .then(() => {
                                  console.log('Completed')
                              });
                          })
                      })

                    //  console.log('Successfully added: ', docRef.id)
                      // alert("Succefully Added Data");
                      swal.fire({
                        title: `Success!`,
                        text: "You have succesfully added progress",
                        buttonsStyling: false,
                        customClass: {
                          confirmButton: "btn btn-danger btn-fill"
                        },
                        icon: "success"
                      });
                      this.$router.push('/startups')
                  });
                })
              })

      },
      fetchData () {
        db.collection('startups')
          .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.startup_doc_id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              this.businessModel = doc.data().businessModel; 
              this.businessModelNow = doc.data().businessModelNow; 
              this.finance = doc.data().finance; 
              this.financeNow = doc.data().financeNow; 
              this.productDev = doc.data().productDev; 
              this.productDevNow = doc.data().productDevNow; 
              this.marketing = doc.data().marketing; 
              this.marketingNow = doc.data().marketingNow; 
              this.teamBuilding = doc.data().teamBuilding; 
              this.teamBuildingNow = doc.data().teamBuildingNow; 
              this.fundraising = doc.data().fundraising; 
              this.fundraisingNow = doc.data().fundraisingNow; 
            });
          });
      },
  }
};
</script>
<style></style>
