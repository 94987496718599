<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <card type="contributions card-plain">
          <div class="row">
            <div class="col-md-8">
              <h1 class="card-title">{{ this.name }}</h1>
              <h3 class="card-category">
                {{ this.program }}<br />
                <b>Duration</b> <br />
                {{ this.start.toDate() | moment("MMM, DD YYYY") }} -
                {{ this.end.toDate() | moment("MMM, DD YYYY") }}
              </h3>
              <p>
                {{ this.description }}
              </p>
              <h2 class="card-category">
                    <b>Partners</b>
                  </h2>
              <div class='row margin-left'>  
                <div v-for='item in this.partner' :key="item.id">
                  <div v-if="typeProg != 3">
                        <router-link
                            v-bind:to="{
                              name: 'Partners-Detail',
                              params: { partner_id: item.id },
                            }"
                        >
                            <base-button type="danger" simple>{{item.name}}</base-button>
                        </router-link>
                  </div>
                  <div v-if="typeProg == 3">
                    <base-button type="danger" simple>{{item.name}}</base-button>
                  </div>
                  
                </div>
              </div>
              
            </div>
            <div v-if="typeProg != 3" class="col-md-2 ml-auto">
              <router-link
                    :to="{
                      name: 'Program-Edit',
                      params: { program_id: $route.params.program_id },
                    }"
                    replace
                  >
                <base-button
                  icon
                  size="sm"
                  class="btn-link">
                    <i class="fas fa-edit"></i>
                </base-button>
              </router-link>
                <base-button
                  @click.native="deleteItem()"
                  icon
                  size="sm"
                  class="btn-link">
                    <i class="fas fa-trash"></i>
                </base-button>
            </div>
          </div>
        </card>
      </div>
      <div class="col-md-12">
        <card class="card-plain">
          <tabs type="primary">
            <tab-pane label="Startups">
              <div class="row">
                <div v-if="typeProg != 3" class="col-md-1 ml-auto">
                  <router-link to="/startup/add" replace>
                    <base-button type="danger" round icon>
                      <i class="fas fa-plus"></i>
                    </base-button>
                  </router-link>
                </div>
                <div class="col-sm-12">
                  <el-table :data="startupData">
                    <el-table-column min-width="50" label="#" align="center">
                      <div class="photo" slot-scope="{ row }">
                        <img :src="row.image" alt="Table image" />
                      </div>
                    </el-table-column>
                    <el-table-column min-width="150" label="Name">
                      <div slot-scope="{ row }">
                        <router-link
                          v-bind:to="{
                            name: 'Startup-Detail',
                            params: { startup_id: row.name, startup_doc_id: row.id, program_id: row.program_id },
                          }"
                        >
                          {{ row.name }}
                        </router-link>
                      </div>
                    </el-table-column>
                    <el-table-column min-width="150" prop="type" label="Type">
                    </el-table-column>
                    <el-table-column
                      min-width="100"
                      prop="members"
                      align="center"
                      label="Members"
                    >
                    </el-table-column>
                    <el-table-column
                      min-width="200"
                      align="right"
                      label="Progress"
                    >
                      <div slot-scope="{ row }">
                        <base-progress
                          label="Progress"
                          value-position="right"
                          type="danger"
                          :value="row.progress"
                        />
                      </div>
                    </el-table-column>
                    <el-table-column v-if="typeProg != 3"
                      min-width="100"
                      header-align="right"
                      align="right"
                      label="Options"
                    >
                      <div 
                        slot-scope="{ row, $index }"
                        class="text-right table-actions"
                      >
                        <el-tooltip
                          content="Edit"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                        >
                        <router-link
                          :to="{
                                name: 'Startup-Edit',
                                params: { startup_id: row.id },
                              }"
                           replace
                          >
                          <base-button icon size="sm" class="btn-link">
                            <i class="fas fa-edit"></i>
                          </base-button>
                        </router-link>
                        </el-tooltip>
                      </div>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>

            <tab-pane label="Curriculum">
              <div class="row">
                <div v-if="typeProg != 3" class="col-md-1 ml-auto">
                  <router-link
                    :to="{
                      name: 'Milestone-Add',
                      params: { program_id: $route.params.program_id },
                    }"
                    replace
                  >
                    <base-button type="danger" round icon>
                      <i class="fas fa-plus"></i>
                    </base-button>
                  </router-link>
                </div>
                <div class="col-sm-12">
                  <el-table
                    header-cell-class-name="table-transparent"
                    header-row-class-name="table-transparent"
                    row-class-name="table-transparent"
                    :data="milestoneData"
                  >
                    <el-table-column
                      min-width="150"
                      sortable
                      label="Name"
                      property="name"
                    ></el-table-column>
                    <el-table-column
                      min-width="200"
                      sortable
                      label="Description"
                      property="description"
                    ></el-table-column>
                    <el-table-column
                      min-width="100"
                      sortable
                      label="Timeline"
                      property="workdays"
                    >
                    <div slot-scope="{ row }">
                        {{ row.workdays.toDate() | moment("MMM, DD YYYY") }} 
                    </div>
                    </el-table-column>
                    <el-table-column
                      min-width="250"
                      sortable
                      align="right"
                      header-align="right"
                      label="Tags"
                    >
                      <div slot-scope="{ row }">
                        <div
                          v-for="tag in row.tags"
                          :key="tag"
                          class="but-margin"
                        >
                          <base-button type="danger" size="sm" simple>{{
                            tag
                          }}</base-button>
                        </div>
                      </div>
                    </el-table-column>
                    <el-table-column v-if="typeProg != 3"
                      min-width="100"
                      header-align="right"
                      align="right"
                      label="Options"
                    >
                      <div 
                        slot-scope="{ row, $index }"
                        class="text-right table-actions"
                      >
                        <el-tooltip
                          content="Edit"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                        >
                        <router-link
                          :to="{
                                name: 'Program-Milestone-Edit',
                                params: { milestone_id: row.id },
                              }"
                           replace
                          >
                          <base-button icon size="sm" class="btn-link">
                            <i class="fas fa-edit"></i>
                          </base-button>
                        </router-link>
                        </el-tooltip>
                      </div>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { TabPane, Tabs, Collapse, CollapseItem } from "src/components";
import { Table, TableColumn } from "element-ui";
import { ImageUpload } from "src/components/index";
import db from "@/components/Firebase/firebaseInit";
import firebase from "firebase";
import { BaseProgress } from "src/components/index";
import swal from 'sweetalert2';



export default {
  components: {
    BaseProgress,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    TabPane,
    Tabs,
    Collapse,
    ImageUpload,
    CollapseItem,
  },
  data() {
    return {
      name: null,
      phone: null,
      type: null,
      email: null,
      address: null,
      images: {
        regular: null,
      },
      activeName: "first",
      tableData: [
        {
          id: 1,
          img: "img/tania.jpg",
          name: "Tania Mike",
          job: "Develop",
          progress: 25,
          since: 2013,
          salary: "€ 99,225",
        },
        {
          id: 2,
          img: "img/robi.jpg",
          name: "John Doe",
          job: "CEO",
          progress: 77,
          since: 2012,
          salary: "€ 89,241",
        },
        {
          id: 3,
          img: "img/lora.jpg",
          name: "Alexa Mike",
          job: "Design",
          progress: 41,
          since: 2010,
          salary: "€ 92,144",
        },
        {
          id: 4,
          img: "img/jana.jpg",
          name: "Jana Monday",
          job: "Marketing",
          progress: 50,
          since: 2013,
          salary: "€ 49,990",
        },
        {
          id: 5,
          img: "img/mike.jpg",
          name: "Paul Dickens",
          job: "Develop",
          progress: 100,
          since: 2015,
          salary: "€ 69,201",
        },
        {
          id: 6,
          img: "img/emilyz.jpg",
          name: "Manuela Rico",
          job: "Manager",
          progress: 15,
          since: 2012,
          salary: "€ 99,201",
        },
      ],
      startupData: [],
      milestoneData: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    db.collection("programs")
      .where(
        firebase.firestore.FieldPath.documentId(),
        "==",
        to.params.program_id
      )
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next((vm) => {
            console.log("RAS: " + doc.data().start);

            vm.id = doc.id;
            vm.name = doc.data().name;
            vm.program = doc.data().program;
            vm.start = doc.data().start;
            vm.end = doc.data().end;
            vm.description = doc.data().description;
            vm.partner = doc.data().partner;

          });
        });
      });
    //Startup data

    db.collection("startups")
      .where("programID", "==", to.params.program_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next((vm) => {
            let members;
            if (doc.data().members == null) {
              members = 0;
            } else {
              members = doc.data().members;
            }
            let progress;
            if (doc.data().progress == null) {
              progress = 0;
            } else {
              progress = doc.data().progress;
            }
            let imgURL;
            if (doc.data().imgURL == null) {
              imgURL = "img/placeholder.jpg";
            } else {
              imgURL = doc.data().imgURL;
            }
            const data = {
              name: doc.data().name,
              id: doc.id,
              program_id: doc.data().programID,
              type: doc.data().type,
              members: members,
              progress: progress,
              image: imgURL,
            };
            vm.startupData.push(data);
          });
        });
      });

    db.collection("milestones")
      .where("program", "==", to.params.program_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          next((vm) => {
            const data = {
              id: doc.id,
              name: doc.data().name,
              description: doc.data().description,
              workdays: doc.data().workdays,
              tags: doc.data().tags,
            };
            vm.milestoneData.push(data);
          });
        });
      });
  },
  methods: {
    deleteItem(){
        swal.fire({
          title: 'Are you sure?',
          text: `Please confirm you want to delete. you cant revert once you have deleted the item.`,
        //   icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Confirm',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
              //DB
            // Delete the Show
            let programID = this.$route.params.program_id
            db
            .collection('programs')
            .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.program_id)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                doc.ref.delete();

                  db
                  .collection('startups')
                  .where('programID', '==', programID)
                  .get()
                  .then(querySnapshot => {
                      querySnapshot.forEach(doc => {
                      doc.ref.delete();
                      console.log('Module Deleted')
                      });
                  }); 

                console.log('Module Deleted')
                });
            });
                //DB
             swal.fire({
                title: 'Completed!',
                text: 'You have deleted the item',
                icon: 'success',
                customClass: {
                confirmButton: 'btn btn-success btn-fill'
                },
                buttonsStyling: false
            }); 
            this.$router.push('/programs')
          }
        });
    },
    onImageChange(file) {
      this.images.regular = file;
    },
  },
  computed: {
    typeProg(){
      let x = this.$store.state.models.type;
      let y;
      if(x == 'admin'){
        y = 1;
      }else if(x == 'program'){
        y = 2;
      }else if(x == 'partner'){
        y = 3;
      }else if(x == 'startup'){
        y = 4;
      }
       return y;
    },
  },
};
</script>
<style lang="scss">
.but-margin {
  margin-bottom: 6px;
}
.nav-pills.nav-pills-primary .nav-item .nav-link.active,
.nav-pills.nav-pills-primary .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-primary .nav-item .nav-link.active:hover {
  background: #ec250d !important;
}
.margin-left{
  margin-left: 1px;
}
</style>
