<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Add New Startup</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Startup Type"
                    rules="required"
                    v-slot="{ errors }"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Select Startup Type"
                  v-model="model.type"
                  :error="errors[0]">
                  <el-option
                    class="select-danger"
                    value="Software (SaaS) and technology"
                    label="Software (SaaS) and technology"
                    key="Software (SaaS) and technology"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Marketing and advertising"
                    label="Marketing and advertising"
                    key="Marketing and advertising"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Healthcare"
                    label="Healthcare"
                    key="Healthcare"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="IT"
                    label="IT"
                    key="IT"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Insurance"
                    label="Insurance"
                    key="Insurance"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Education"
                    label="Education"
                    key="Education"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Real estate"
                    label="Real estate"
                    key="Real estate"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Environmental and energy"
                    label="Environmental and energy"
                    key="Environmental and energy"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Retail and ecommerce"
                    label="Retail and ecommerce"
                    key="Retail and ecommerce"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Blockchain and cryptocurrencies"
                    label="Blockchain and cryptocurrencies"
                    key="Blockchain and cryptocurrencies"
                  >
                  </el-option>

                </el-select>
              </ValidationProvider>
            </div>
            <br>
            <div class="col-md-8">
              <ValidationProvider
                    name="Startup Stage"
                    rules="required"
                    v-slot="{ errors }"
              >
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Select Startup Stage"
                  v-model="model.stage"
                  :error="errors[0]">
                  <el-option
                    class="select-danger"
                    value="Self-funding"
                    label="Self-funding"
                    key="Self-funding"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Pre-Seed"
                    label="Pre-Seed"
                    key="Pre-Seed"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Seed"
                    label="Seed"
                    key="Seed"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Venture"
                    label="Venture"
                    key="Venture"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Series A"
                    label="Series A"
                    key="Series A"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Series B"
                    label="Series B"
                    key="Series B"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="Series C"
                    label="Series C"
                    key="Series C"
                  >
                  </el-option>
                  <el-option
                    class="select-danger"
                    value="IPO"
                    label="IPO"
                    key="IPO"
                  >
                  </el-option>
                </el-select>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Phone"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Phone No."
                  v-model="model.phone"
                  type="text"
                  placeholder="Enter startup phone No."
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Email"
                    rules="required|email"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="Email"
                v-model="model.email"
                type="text"
                placeholder="Enter startup email"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Address"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="Address"
                v-model="model.address"
                type="text"
                placeholder="Enter startup address"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="City"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="City"
                v-model="model.city"
                type="text"
                placeholder="Enter startup city"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Website"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="Website"
                v-model="model.website"
                type="text"
                placeholder="Enter startup website"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Team"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Team"
                  v-model="model.team"
                  type="text"
                  placeholder="Enter number of team meambers"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Valuation"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Valuation(USD)"
                  v-model="model.valuation"
                  type="text"
                  placeholder="Enter startup valuation (USD)"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Initial Capital"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Initial Capital / Investment"
                  v-model="model.initialCap"
                  type="text"
                  placeholder="Enter startup initial capital / Investment"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Profits"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Profits"
                  v-model="model.profits"
                  type="text"
                  placeholder="Enter startup profits"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Assets"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Assets"
                  v-model="model.assets"
                  type="text"
                  placeholder="Enter startup assets"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="Jobs Created"
                    rules="required|numeric"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Jobs Created"
                  v-model="model.jobs"
                  type="text"
                  placeholder="Enter startup Jobs Created"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
                <textarea class="form-text form-control" v-model="model.description" placeholder="Enter description" label="Description" name="" id="" cols="30" rows="10"></textarea>
                <br>
            </div>
            <div class="col-md-8">
                <base-button class="mt-4" id="submit" native-type="submit" type="danger"
                >Save</base-button
                >
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Select, Option } from 'element-ui';
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
        model: {
            name: null,
            program: null,
            type: null,
            address: null,
            email: null,
            phone: null,
            description: null,
            valuation: null,
            initialCap: null,
            profits: null,
            jobs: null,
            assets: null,
            stage: null,
            city: null,
            website: null,
            teaam: null,
            valuationOld: null,
            initialCapOld: null,
            profitsOld: null,
            jobsOld: null,
            assetsOld: null
        }
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(to.params.startup_doc_id + " sdf")
    db
      .collection('startups').where(firebase.firestore.FieldPath.documentId(), '==', to.params.startup_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.model.name = doc.data().name; 
            vm.model.program = doc.data().programID;
            vm.model.type = doc.data().type; 
            vm.model.phone = doc.data().phone;
            vm.model.email = doc.data().email;
            vm.model.address = doc.data().address;
            vm.model.description = doc.data().description;
            vm.model.valuation = doc.data().valuation;
            vm.model.initialCap = doc.data().initialCap;
            vm.model.jobs = doc.data().jobs;
            vm.model.assets = doc.data().assets;
            vm.model.profits = doc.data().profits;
            vm.model.stage = doc.data().stage;
            vm.model.city = doc.data().city;
            vm.model.website = doc.data().website;
            vm.model.team = doc.data().team;

            vm.model.valuationOld = doc.data().valuation;
            vm.model.initialCapOld = doc.data().initialCap;
            vm.model.jobsOld = doc.data().jobs;
            vm.model.assetsOld = doc.data().assets;
            vm.model.profitsOld = doc.data().profits;

          });
        });
      });
  },
   watch: {
    $route: 'fetchData'
  },
  methods: {
      fetchData () {
        db.collection('startups')
          .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.startup_id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {

                this.model.name = doc.data().name; 
                this.model.type = doc.data().type; 
                this.model.program = doc.data().programID;
                this.model.phone = doc.data().phone;
                this.model.email = doc.data().email;
                this.model.address = doc.data().address;
                this.model.description = doc.data().description;
                this.model.valuation = doc.data().valuation;
                this.model.initialCap = doc.data().initialCap;
                this.model.jobs = doc.data().jobs;
                this.model.assets = doc.data().assets;
                this.model.profits = doc.data().profits;
                this.model.stage = doc.data().stage;
                this.model.city = doc.data().city;
                this.model.website = doc.data().website;
                this.model.team = doc.data().team;

                this.model.valuationOld = doc.data().valuation;
                this.model.initialCapOld = doc.data().initialCap;
                this.model.jobsOld = doc.data().jobs;
                this.model.assetsOld = doc.data().assets;
                this.model.profitsOld = doc.data().profits;

            });
          });
      },
      submit(){
        document.getElementById('submit').disabled=true;
        let programID = this.model.program;

        db.collection('startups').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.startup_id).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            doc.ref.update({
                type: this.model.type,
                phone: this.model.phone,
                email: this.model.email,
                address: this.model.address,
                description: this.model.description,
                stage: this.model.stage,
                city: this.model.city,
                website: this.model.website,
                team: this.model.team,
                valuation: this.model.valuation,
                initialCap: this.model.initialCap,
                jobs: this.model.jobs,
                assets: this.model.assets,
                profits: this.model.profits
            })
            .then(() => {
              console.log('Module have been Updated')


            db.collection('programs').where(firebase.firestore.FieldPath.documentId(), '==', programID).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {

                    let jobsNew = Number(this.model.jobs) - Number(this.model.jobsOld);
                    let profitsNew = Number(this.model.profits) - Number(this.model.profitsOld);
                    let assetsNew = Number(this.model.assets) - Number(this.model.assetsOld);
                    let initialCapNew = Number(this.model.initialCap) - Number(this.model.initialCapOld);
                    let valuationNew = Number(this.model.valuation) - Number(this.model.valuationOld);
                    
                    let jobs = Number(doc.data().jobs) + jobsNew;
                    let profits = Number(doc.data().profits) + profitsNew;
                    let assets = Number(doc.data().assets) + assetsNew;
                    let initialCap = Number(doc.data().initialCap) + initialCapNew;
                    let valuation = Number(doc.data().valuation) + valuationNew;

                    doc.ref.update({
                        jobs: jobs,
                        profits: profits,
                        assets: assets,
                        initialCap: initialCap,
                        valuation: valuation
                    })
                    .then(() => {
                        console.log('Completed')
                    });
                })
            })


            console.log('Successfully editied')
            // alert("Succefully Added Data");
            swal.fire({
              title: `Success!`,
              text: "You have succesfully edited startup",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill"
              },
              icon: "success"
            });
            this.$router.push('/startups')


            });
         })
       })

      }
  }
};
</script>
<style>
</style>
