var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',{attrs:{"type":"contributions card-plain"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('h1',{staticClass:"card-title"},[_vm._v(_vm._s(this.name))]),_c('h3',{staticClass:"card-category"},[_vm._v(" "+_vm._s(this.program)),_c('br'),_c('b',[_vm._v("Duration")]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(this.start.toDate(),"MMM, DD YYYY"))+" - "+_vm._s(_vm._f("moment")(this.end.toDate(),"MMM, DD YYYY"))+" ")]),_c('p',[_vm._v(" "+_vm._s(this.description)+" ")]),_c('h2',{staticClass:"card-category"},[_c('b',[_vm._v("Partners")])]),_c('div',{staticClass:"row margin-left"},_vm._l((this.partner),function(item){return _c('div',{key:item.id},[(_vm.typeProg != 3)?_c('div',[_c('router-link',{attrs:{"to":{
                            name: 'Partners-Detail',
                            params: { partner_id: item.id },
                          }}},[_c('base-button',{attrs:{"type":"danger","simple":""}},[_vm._v(_vm._s(item.name))])],1)],1):_vm._e(),(_vm.typeProg == 3)?_c('div',[_c('base-button',{attrs:{"type":"danger","simple":""}},[_vm._v(_vm._s(item.name))])],1):_vm._e()])}),0)]),(_vm.typeProg != 3)?_c('div',{staticClass:"col-md-2 ml-auto"},[_c('router-link',{attrs:{"to":{
                    name: 'Program-Edit',
                    params: { program_id: _vm.$route.params.program_id },
                  },"replace":""}},[_c('base-button',{staticClass:"btn-link",attrs:{"icon":"","size":"sm"}},[_c('i',{staticClass:"fas fa-edit"})])],1),_c('base-button',{staticClass:"btn-link",attrs:{"icon":"","size":"sm"},nativeOn:{"click":function($event){return _vm.deleteItem()}}},[_c('i',{staticClass:"fas fa-trash"})])],1):_vm._e()])])],1),_c('div',{staticClass:"col-md-12"},[_c('card',{staticClass:"card-plain"},[_c('tabs',{attrs:{"type":"primary"}},[_c('tab-pane',{attrs:{"label":"Startups"}},[_c('div',{staticClass:"row"},[(_vm.typeProg != 3)?_c('div',{staticClass:"col-md-1 ml-auto"},[_c('router-link',{attrs:{"to":"/startup/add","replace":""}},[_c('base-button',{attrs:{"type":"danger","round":"","icon":""}},[_c('i',{staticClass:"fas fa-plus"})])],1)],1):_vm._e(),_c('div',{staticClass:"col-sm-12"},[_c('el-table',{attrs:{"data":_vm.startupData}},[_c('el-table-column',{attrs:{"min-width":"50","label":"#","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return _c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":row.image,"alt":"Table image"}})])}}])}),_c('el-table-column',{attrs:{"min-width":"150","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return _c('div',{},[_c('router-link',{attrs:{"to":{
                          name: 'Startup-Detail',
                          params: { startup_id: row.name, startup_doc_id: row.id, program_id: row.program_id },
                        }}},[_vm._v(" "+_vm._s(row.name)+" ")])],1)}}])}),_c('el-table-column',{attrs:{"min-width":"150","prop":"type","label":"Type"}}),_c('el-table-column',{attrs:{"min-width":"100","prop":"members","align":"center","label":"Members"}}),_c('el-table-column',{attrs:{"min-width":"200","align":"right","label":"Progress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return _c('div',{},[_c('base-progress',{attrs:{"label":"Progress","value-position":"right","type":"danger","value":row.progress}})],1)}}])}),(_vm.typeProg != 3)?_c('el-table-column',{attrs:{"min-width":"100","header-align":"right","align":"right","label":"Options"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
                        var $index = ref.$index;
return _c('div',{staticClass:"text-right table-actions"},[_c('el-tooltip',{attrs:{"content":"Edit","effect":"light","open-delay":300,"placement":"top"}},[_c('router-link',{attrs:{"to":{
                              name: 'Startup-Edit',
                              params: { startup_id: row.id },
                            },"replace":""}},[_c('base-button',{staticClass:"btn-link",attrs:{"icon":"","size":"sm"}},[_c('i',{staticClass:"fas fa-edit"})])],1)],1)],1)}}],null,false,2948045057)}):_vm._e()],1)],1)])]),_c('tab-pane',{attrs:{"label":"Curriculum"}},[_c('div',{staticClass:"row"},[(_vm.typeProg != 3)?_c('div',{staticClass:"col-md-1 ml-auto"},[_c('router-link',{attrs:{"to":{
                    name: 'Milestone-Add',
                    params: { program_id: _vm.$route.params.program_id },
                  },"replace":""}},[_c('base-button',{attrs:{"type":"danger","round":"","icon":""}},[_c('i',{staticClass:"fas fa-plus"})])],1)],1):_vm._e(),_c('div',{staticClass:"col-sm-12"},[_c('el-table',{attrs:{"header-cell-class-name":"table-transparent","header-row-class-name":"table-transparent","row-class-name":"table-transparent","data":_vm.milestoneData}},[_c('el-table-column',{attrs:{"min-width":"150","sortable":"","label":"Name","property":"name"}}),_c('el-table-column',{attrs:{"min-width":"200","sortable":"","label":"Description","property":"description"}}),_c('el-table-column',{attrs:{"min-width":"100","sortable":"","label":"Timeline","property":"workdays"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(_vm._f("moment")(row.workdays.toDate(),"MMM, DD YYYY"))+" ")])}}])}),_c('el-table-column',{attrs:{"min-width":"250","sortable":"","align":"right","header-align":"right","label":"Tags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return _c('div',{},_vm._l((row.tags),function(tag){return _c('div',{key:tag,staticClass:"but-margin"},[_c('base-button',{attrs:{"type":"danger","size":"sm","simple":""}},[_vm._v(_vm._s(tag))])],1)}),0)}}])}),(_vm.typeProg != 3)?_c('el-table-column',{attrs:{"min-width":"100","header-align":"right","align":"right","label":"Options"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
                  var $index = ref.$index;
return _c('div',{staticClass:"text-right table-actions"},[_c('el-tooltip',{attrs:{"content":"Edit","effect":"light","open-delay":300,"placement":"top"}},[_c('router-link',{attrs:{"to":{
                              name: 'Program-Milestone-Edit',
                              params: { milestone_id: row.id },
                            },"replace":""}},[_c('base-button',{staticClass:"btn-link",attrs:{"icon":"","size":"sm"}},[_c('i',{staticClass:"fas fa-edit"})])],1)],1)],1)}}],null,false,4067536344)}):_vm._e()],1)],1)])])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }