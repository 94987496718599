var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',{staticClass:"stacked-form",attrs:{"title":"Stacked Form"}},[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v("Settings")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',[_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"Password","type":"password","placeholder":"Enter Password","error":errors[0]},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"New Password","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"New Password","type":"password","placeholder":"Enter New Password","error":errors[0]},model:{value:(_vm.model.newPassword),callback:function ($$v) {_vm.$set(_vm.model, "newPassword", $$v)},expression:"model.newPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('ValidationProvider',{attrs:{"name":"Re-Enter New Password","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"Re-Enter New Password","type":"password","placeholder":"Re-enter New Password","error":errors[0]},model:{value:(_vm.model.reNewPassword),callback:function ($$v) {_vm.$set(_vm.model, "reNewPassword", $$v)},expression:"model.reNewPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('base-button',{staticClass:"mt-4",attrs:{"id":"submit","native-type":"submit","type":"danger"}},[_vm._v("Save")])],1)])])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }