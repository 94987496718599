<template>
  <div class="login-content" 
    overflow-x: scroll;
>
    <div class="row">
      <div class="col-md-8 col-sm-12 mr-auto login-left">
        <div class="row login-container">
          <div class="header">
            <img class="ice180-logo" src="img/logo.svg" alt="ice180" />
            <br />
            <!-- <p class="card-description">
              Startups and SMEs tracking tool for startup support programs, incubators and accelerators.  Helps Companies to manage their progress and share resources.
            </p> -->
          </div>
          <div class="login row">
            <h2 class="card-title">Register your Startup</h2>
            <div class="col-md-6">
              <ValidationProvider
                    name="Name"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Name"
                  v-model="model.name"
                  type="text"
                  placeholder="Enter startup name"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                    name="Website"
                    rules="url"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Website"
                  v-model="model.website"
                  type="text"
                  placeholder="Enter startup website"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                    name="Phone"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Contact Person Phone"
                  v-model="model.phone"
                  type="text"
                  placeholder="Enter startup phone No."
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                    name="Email"
                    rules="required|email"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="Contact Person Email"
                v-model="model.email"
                type="text"
                placeholder="Enter startup email"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                    name="Password"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="Password"
                v-model="model.password"
                type="password"
                placeholder="Enter Password"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                    name="Address"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="Address"
                v-model="model.address"
                type="text"
                placeholder="Enter startup address"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                    name="City"
                    rules="required|min:3"
                    v-slot="{ passed, failed, errors }"
              >
                <base-input
                required
                label="City"
                v-model="model.city"
                type="text"
                placeholder="Enter startup city"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                    name="Number of Team Meambers"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
              >
                  <base-input
                  required
                  label="Number of Team Meambers"
                  v-model="model.team"
                  type="number"
                  placeholder="Enter number of Team Meambers"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-12">
                <textarea class="form-text form-control" v-model="model.description" placeholder="Enter description" label="Description" name="" id="" cols="30" rows="10"></textarea>
                <br>
            </div>

            <div slot="footer" class="mar-top">

              <div v-if="loginButton == true">
                <base-button
                  @click.native="login"
                  type="danger"
                  class="login-btn"
                  size="lg"
                  block
                  disabled
                >                
                    <svg width="20" version="1.1" id="L3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                    <circle fill="none" stroke="#fff" stroke-width="4" cx="50" cy="50" r="44" style="opacity:0.5;"/>
                      <circle fill="#fff" stroke="#e74c3c" stroke-width="3" cx="8" cy="54" r="6" >
                        <animateTransform
                          attributeName="transform"
                          dur="2s"
                          type="rotate"
                          from="0 50 48"
                          to="360 50 52"
                          repeatCount="indefinite" />
                        
                      </circle>
                    </svg>
                </base-button>
              </div>
              <div v-if="loginButton == false">
                <base-button
                  @click.native="submit"
                  type="danger"
                  class="login-btn"
                  id="submit"
                  size="lg"
                  block
                >                
                Register
                </base-button>
              </div>

              <!-- <div class="pull-left">
                <h6>
                  <router-link class="link footer-link" to="/register">
                    Create Account
                  </router-link>
                </h6>
              </div>

              <div class="pull-right">
                <h6><a href="#pablo" class="link footer-link">Need Help?</a></h6>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 login-right">
        <img src="img/login-animated.svg" width="100%" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import db from "@/components/Firebase/firebaseInit";
import swal from "sweetalert2";
import firebase from "firebase";

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  data() {
    return {
      email: "",
      password: "",
      loginButton: false,
      model: {
            name: null,
            program: null,
            type: null,
            address: null,
            email: null,
            password: null,
            phone: null,
            city: null,
            website: null,
            description: null,
            valuationn: null,
            initialCap: null,
            profits: null,
            jobs: null,
            assets: null,
            team: null,
        }
    };
  },
  methods: {
     submit(){
        document.getElementById('submit').disabled=true;

        let idDate = Number(new Date().valueOf());
        console.log("thiss os   " + this.$route.query.program);
        let items = this.$route.query.program;
        console.log(items + " items");
        let content = items.split("--");
        let name = content[0];
        let id = content[1];
        console.log(name + " name");
        console.log(id + " id");
        let email = this.model.email;
        let password = this.model.password;

        // add the data to db
        db.collection('startups').add({
            name: this.model.name,
            program: name,
            programID: id,
            partnerID: '',
            partnerName: '',
            type: '',
            website: this.model.website,
            phone: this.model.phone,
            email: this.model.email,
            address: this.model.address,
            city: this.model.city,
            description: this.model.description,
            valuation: '0',
            initialCap: '0',
            time: idDate,
            jobs: '0',
            assets: '0',
            profits:'0',
            totalMilestones: 0,
            team: this.model.team,
            completedMilestones: 0,
            meambers: 0,  
            businessModel : 0,
            businessModelNow : 0, 
            finance : 0,
            financeNow : 0, 
            productDev : 0, 
            productDevNow : 0, 
            marketing : 0,
            marketingNow : 0, 
            teamBuilding : 0, 
            teamBuildingNow : 0, 
            fundraising : 0, 
            fundraisingNow : 0 


        })
        .then(docRef => {

                        firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log(errorCode + ": " + errorMessage);
                        // ...
                        }); 


              db.collection('userbase').add({
                  email: email,
                  userID: docRef.id,
                  type: 'startup',
              })
              .then(docRef => {
                console.log('Successfully added to userbase')
              })



            let content = this.$route.query.program.split("--");
            let id = content[1];
            let partnerID = content[2];
            db.collection('programs').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let startups = Number(doc.data().startups) + 1;
                    doc.ref.update({
                        startups: startups,
                    })
                    .then(() => {
                        console.log('Completed')
                            swal.fire({
                            title: `Success!`,
                            text: "You have succesfully added startup",
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: "btn btn-danger btn-fill"
                            },
                            icon: "success"
                            });
                            this.$router.push('/login')
                    });
                })
            })

            console.log('Successfully added')
            // alert("Succefully Added Data");
            swal.fire({
              title: `Success!`,
              text: "You have succesfully added startup",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-danger btn-fill"
              },
              icon: "success"
            });
            this.$router.push('/startups')
        })
        .catch(error => {
            console.error('Error adding Data: ', error)
        })

      }
  },
};
</script>
<style lang="scss">
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.txt-black {
  color: #000 !important;
}
.login-content {
  height: 100vh;
  overflow: hidden;
  display: grid;
  .login-left {
    box-shadow: 0 0 10rem rgba(9, 25, 40, 0.1);

    .login-container {
      height: 100%;
      max-width: 50%;
      place-content: center;
      row-gap: 2em;
      margin: 0 auto;
      display: grid;
      @media only screen and (max-width: 768px) {
        max-width: 80%;
      }
      .ice180-logo {
        height: 4.5rem;
      }
      .lni-eye {
        color: grey;
      }
      .login-after {
        display: flex;
        justify-content: space-between;
        .form-check {
          margin-top: 0px;
        }
      }
      .login-btn {
        border-radius: 2em;
        border-top-right-radius: 0px;
        box-shadow: 0 0.5rem 1rem #ec230d41;
        transition: all 500ms;
        &:hover {
          background: rgb(9, 25, 40) !important;
        }
        &:disabled{
          background: rgb(9, 25, 40) !important;
        }
      }
    }
  }
  .login-right {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABGdBTUEAALGPC/xhBQAAACxJREFUKBVjYBgFwzcE/v//3wrEn0E0Ub6EKgZS/z8zEaWDgWESUN0XIJ4EABn/GOtiU2ETAAAAAElFTkSuQmCC");
    display: grid;
    place-items: center;
    background-color: rgba(9, 25, 40, 0.03125);
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}
</style>
<style scoped>
.mar-top {
  margin-top: 50px;
}
</style>
