<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Edit Curriculum</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Name"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Name"
                  v-model="model.name"
                  type="text"
                  placeholder="Enter milestone name"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                    name="End Date"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
              >
                  <el-date-picker
                    required
                    type="date"
                    label="End Date"
                    placeholder="End Date"
                    v-model="model.workdays"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </el-date-picker>
              </ValidationProvider>
            </div>
            <br>
            <div class="col-md-8">
                <tags-input label="Tags" v-model="model.tags"></tags-input>
            </div>
            <div class="col-md-8">
                <textarea class="form-text form-control" v-model="model.description" placeholder="Enter program description" label="Description" name="" id="" cols="30" rows="10"></textarea>
                <br>
            </div>
            <div class="col-md-8">
                <base-button class="mt-4" id="submit" native-type="submit" type="success"
                >Save</base-button>
                <base-button class="mt-4" @click.native="deleteItem()" type="danger"
                >Delete</base-button>
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Select, Option } from 'element-ui';
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import {
  TagsInput
} from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    TagsInput
  },
  data() {
    return {
        model: {
            name: null,
            description: null,
            workdays: null,
            tags: null,
        }
    };
  },
  beforeRouteEnter(to, from, next) {
    // console.log(to.params.startup_doc_id + " sdf")
    db
      .collection('milestones').where(firebase.firestore.FieldPath.documentId(), '==', to.params.milestone_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.model.name = doc.data().name; 
            vm.model.workdays = new Date(Number(doc.data().workdays.seconds) * 1000); 
            vm.model.description = doc.data().description; 
            vm.model.tags = doc.data().tags; 
            console.log(doc.data().tags + " this is tag");

            // let partnerData = doc.data().tags;
            //     partnerData.forEach(unsplitFunction); 
            //     function unsplitFunction(item, index) {
            //         vm.model.tags.push(item);
            //       }

          });
        });
      });
  },
   watch: {
    $route: 'fetchData'
  },
  methods: {
      fetchData () {
        db.collection('programs')
          .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.program_id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
               this.model.name = doc.data().name; 
               this.model.workdays = new Date(Number(doc.data().workdays.seconds) * 1000); 
               this.model.description = doc.data().description; 
               this.model.tags = doc.data().tags; 
              
              // let partnerData = doc.data().tags;
              //   partnerData.forEach(unsplitFunction); 
              //   function unsplitFunction(item, index) {
              //       let name = item.name;
              //       let id = item.id;
              //       let data = id + "--" + name;
              //       this.model.partner.push(data);
              //     }

            });
          });
      },
      submit(){
      document.getElementById('submit').disabled=true;

        // Values 
        let names = this.model.name;
        let workdays = this.model.workdays;
        let description = this.model.description;
        let tags = this.model.tags;
        let router = this.$router;

                db.collection('milestones').where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.milestone_id).get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.update({
                          name: names,
                          workdays: workdays,
                          description: description,
                          tags: tags
                    })
                    .then(() => {
                    console.log('module have been Updated')
                          // alert("Succefully Added Data");
                          swal.fire({
                            title: `Success!`,
                            text: "You have succesfully editied the item",
                            buttonsStyling: false,
                            customClass: {
                              confirmButton: "btn btn-danger btn-fill"
                            },
                            icon: "success"
                          });
                          router.push('/programs')
                    });
                  });
                });


      },
    deleteItem(){
        swal.fire({
          title: 'Are you sure?',
          text: `Please confirm you want to delete. you cant revert once you have deleted the item.`,
        //   icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Confirm',
          buttonsStyling: false
        }).then((result) => {
          if(result.value){
              //DB
            // Delete the Show
            let programID = this.$route.params.program_id
            db
            .collection('milestones')
            .where(firebase.firestore.FieldPath.documentId(), '==', this.$route.params.milestone_id)
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {
                doc.ref.delete();

                console.log('Module Deleted')
                });
            });
                //DB
             swal.fire({
                title: 'Completed!',
                text: 'You have deleted the item',
                icon: 'success',
                customClass: {
                confirmButton: 'btn btn-success btn-fill'
                },
                buttonsStyling: false
            }); 
            this.$router.push('/programs')
          }
        });
    },
  }
};
</script>
<style>
</style>
