var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-12 mr-auto"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-8 mar-top mr-auto ml-auto"},[_c('h2',{staticClass:"card-title"},[_vm._v(" Login ")]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"Email","type":"email","placeholder":"Email","addon-left-icon":"tim-icons icon-email-85","error":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('base-input',{class:[{ 'has-success': passed }, { 'has-danger': failed }],attrs:{"required":"","label":"Password","placeholder":"Password","addon-left-icon":"tim-icons icon-lock-circle","type":"password","error":errors[0]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}])})],1),_c('div',{staticClass:"mar-top",attrs:{"slot":"footer"},slot:"footer"},[_c('base-button',{staticClass:"mb-3",attrs:{"type":"danger","size":"lg","block":""},nativeOn:{"click":function($event){return _vm.login.apply(null, arguments)}}},[_vm._v(" Login ")])],1)])])])]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-8 mar-top mr-auto ml-auto"},[_c('img',{attrs:{"src":"img/ice180.png","width":"50%","alt":""}}),_c('p',{staticClass:"card-description"},[_vm._v("Incubation program aimed to facilitate the ideation, prototyping, development and scaling of promising Ethiopian startups. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-6 col-sm-12"},[_c('img',{attrs:{"src":"img/loginback.png","width":"100%","alt":""}})])}]

export { render, staticRenderFns }