<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form" title="Stacked Form">
        <h4 slot="header" class="card-title">Settings</h4>
        <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Password"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
               >
                <base-input
                required
                label="Password"
                v-model="model.password"
                type="password"
                placeholder="Enter Password"
                :error="errors[0]"
                :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="New Password"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="New Password"
                  v-model="model.newPassword"
                  type="password"
                  placeholder="Enter New Password"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
              <ValidationProvider
                  name="Re-Enter New Password"
                  rules="required|min:3"
                  v-slot="{ passed, failed, errors }"
               >
                  <base-input
                  required
                  label="Re-Enter New Password"
                  v-model="model.reNewPassword"
                  type="password"
                  placeholder="Re-enter New Password"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  </base-input>
              </ValidationProvider>
            </div>
            <div class="col-md-8">
                <base-button class="mt-4" id="submit" native-type="submit" type="danger"
                >Save</base-button
                >
            </div>
          </div>
        </form>
        </ValidationObserver>
      </card>
    </div>
    <!-- end card -->
  </div>
</template>
<script>
import { DatePicker, Select, Option } from 'element-ui';
import db from '@/components/Firebase/firebaseInit'
import firebase from 'firebase';
import swal from "sweetalert2";
import {
  TagsInput
} from 'src/components/index';
import { extend } from "vee-validate";
import { required, email, numeric, min } from "vee-validate/dist/rules";
import { configure } from 'vee-validate';
import { Upload } from 'element-ui'; 

extend("email", email);
extend("numeric", numeric);
extend("required", required);
extend("min", min);

export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    TagsInput,
    [Upload.name]: Upload
  },
  data() {
    return {
        model: {
            password: null,
            newPassword: null,
            reNewPassword: null
        }
    };
  },
  methods: {
        submit () {

            if(this.model.newPassword == this.model.reNewPassword){

            var user = firebase.auth().currentUser;
            var cred = firebase.auth.EmailAuthProvider.credential(
                    user.email, this.model.password);

            user.reauthenticateAndRetrieveDataWithCredential(cred).then(() => {

                var user = firebase.auth().currentUser;
                user.updatePassword(this.model.newPassword).then(() => {
                
                console.log("Password updated!");
                swal.fire({
                title: `Password Updated!`,
                text: 'You have updated password succesfully',
                buttonsStyling: false,
                customClass: {
                          confirmButton: "btn btn-danger btn-fill"
                        },
                icon: "success"
                });
                this.$router.push('/dashboard')
                
                }).catch((error) => {          
                    console.log(error);
                    swal.fire({
                    title: `Error!`,
                    text: error,
                    buttonsStyling: false,
                    customClass: {
                          confirmButton: "btn btn-danger btn-fill"
                        },
                    icon: "error"
                    });                      
                    this.$router.push('/settings')
                
                });

            }).catch((error) => {
                console.log(error); 
                    swal.fire({
                    title: `Error!`,
                    text: error,
                    buttonsStyling: false,
                    customClass: {
                          confirmButton: "btn btn-danger btn-fill"
                        },
                    icon: "error"
                    }); 
                this.$router.push('/settings')
                    
                });

            }else{
                    swal.fire({
                    title: `Error!`,
                    text: 'The new passwords doesn\'t match.',
                    buttonsStyling: false,
                    customClass: {
                          confirmButton: "btn btn-danger btn-fill"
                        },
                    icon: "error"
                    }); 
                this.$router.push('/settings')
            }   

    }
  }
};
</script>
<style>
</style>
