<template>
  <div class="login-content">
    <div class="row">
      <div class="col-md-6 col-sm-12 mr-auto login-left">
        <div class="row login-container">
          <div class="header">
            <img class="ice180-logo" src="img/logo.svg" alt="ice180" />
            <br /><br />
            <p class="card-description">
              Startups and SMEs tracking tool for startup support programs, incubators and accelerators.
            </p>
          </div>
          <div class="login">
            <h2 class="card-title">Log in</h2>
            <div>
              <ValidationProvider
                name="email"
                rules="required|email"
                v-slot="{ passed, failed, errors }"
              >
                <base-input
                  required
                  label="Email Address"
                  v-model="email"
                  type="email"
                  placeholder="you@email.com"
                  addon-left-icon="lni lni-envelope"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
              </ValidationProvider>

              <ValidationProvider
                name="password"
                rules="required|min:5"
                v-slot="{ passed, failed, errors }"
              >
                <!-- addon-right-icon="lni lni-eye" -->

                <base-input
                  required
                  v-model="password"
                  label="Password"
                  placeholder="Password"
                  addon-left-icon="lni lni-lock-alt"
                  type="password"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                >
                </base-input>
                <div class="login-after">
                  <a href="/#/forgotpassword" class="forgot-password"
                    >Forgot Password?</a
                  >
                  <base-checkbox> Keep me logged in </base-checkbox>
                </div>
              </ValidationProvider>
            </div>

            <div slot="footer" class="mar-top">
              <div v-if="loginButton == true">
                <base-button
                  @click.native="login"
                  type="danger"
                  class="login-btn"
                  size="lg"
                  block
                  disabled
                >
                  <svg
                    width="20"
                    version="1.1"
                    id="L3"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    enable-background="new 0 0 0 0"
                    xml:space="preserve"
                  >
                    <circle
                      fill="none"
                      stroke="#fff"
                      stroke-width="4"
                      cx="50"
                      cy="50"
                      r="44"
                      style="opacity: 0.5"
                    />
                    <circle
                      fill="#fff"
                      stroke="#e74c3c"
                      stroke-width="3"
                      cx="8"
                      cy="54"
                      r="6"
                    >
                      <animateTransform
                        attributeName="transform"
                        dur="2s"
                        type="rotate"
                        from="0 50 48"
                        to="360 50 52"
                        repeatCount="indefinite"
                      />
                    </circle>
                  </svg>
                </base-button>
              </div>
              <div v-if="loginButton == false">
                <base-button
                  @click.native="login"
                  type="danger"
                  class="login-btn"
                  size="lg"
                  block
                >
                  Login
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 login-right">
        <img src="img/login-animated.svg" width="100%" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import db from "@/components/Firebase/firebaseInit";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import firebase from "firebase";

extend("email", email);
extend("min", min);

extend("required", required);

export default {
  data() {
    return {
      email: "",
      password: "",
      loginButton: false,
    };
  },
  methods: {
    ...mapActions(["userLogin"]),
    async login() {
      this.loginButton = true;
      let router = this.$router;
      this.userLogin({
        email: this.email,
        password: this.password,
      })
        .then(() => {
          router.push("/dashboard");
        })
        .catch((err) => {
          this.loginButton = false;
          Swal.fire(err.message)
        });
    },
  },
};
</script>
<style lang="scss">
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.txt-black {
  color: #000 !important;
}
.login-content {
  height: 100vh;
  overflow: hidden;
  display: grid;
  .login-left {
    box-shadow: 0 0 10rem rgba(9, 25, 40, 0.1);

    .login-container {
      height: 100%;
      max-width: 50%;
      place-content: center;
      row-gap: 2em;
      margin: 0 auto;
      display: grid;
      @media only screen and (max-width: 768px) {
        max-width: 80%;
      }
      .ice180-logo {
        height: 4.5rem;
      }
      .lni-eye {
        color: grey;
      }
      .login-after {
        display: flex;
        justify-content: space-between;
        .form-check {
          margin-top: 0px;
        }
      }
      .login-btn {
        border-radius: 2em;
        border-top-right-radius: 0px;
        box-shadow: 0 0.5rem 1rem #ec230d41;
        transition: all 500ms;
        &:hover {
          background: rgb(9, 25, 40) !important;
        }
        &:disabled {
          background: rgb(9, 25, 40) !important;
        }
      }
    }
  }
  .login-right {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABGdBTUEAALGPC/xhBQAAACxJREFUKBVjYBgFwzcE/v//3wrEn0E0Ub6EKgZS/z8zEaWDgWESUN0XIJ4EABn/GOtiU2ETAAAAAElFTkSuQmCC");
    display: grid;
    place-items: center;
    background-color: rgba(9, 25, 40, 0.03125);
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}
</style>
<style scoped>
.mar-top {
  margin-top: 50px;
}
</style>
